import React from "react"
import { FormattedNumber } from "gatsby-plugin-intl"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import Button from "../components/button"

const styles = theme => ({
  subscriptionOption: {
    backgroundColor: "white",
    marginBottom: "2rem",
    padding: "1.8rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",

    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  divider: {
    margin: "2rem 0",
  },
  planButton: {
    marginTop: "1.5rem",
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
})

const SubscriptionItem = props => {
  const {
    classes,
    title,
    price,
    disabled,
    divider,
    description,
    buttonText,
    to,
    selected,
  } = props

  return (
    <Paper
      className={classnames(
        classes.subscriptionOption,
        selected ? classes.selected : ""
      )}
    >
      <Typography component={"h3"} variant="subtitle2">
        {title}
      </Typography>
      <Typography variant="h4">
        {isNaN(price) ? (
          price
        ) : (
          <FormattedNumber
            currency="USD"
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            value={price}
            minimumFractionDigits={0}
          />
        )}
      </Typography>
      {divider && <Divider className={classes.divider} />}
      <Typography>{description}</Typography>
      <div>
        <Button disabled={disabled} className={classes.planButton} href={to}>
          {buttonText}
        </Button>
      </div>
    </Paper>
  )
}

SubscriptionItem.defaultProps = {
  divider: true,
}

export default withStyles(styles)(SubscriptionItem)
