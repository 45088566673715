import React from "react"
import { Helmet } from "react-helmet"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import PublicLayout from "../components/layouts/PublicLayout"
import SubscriptionItem from "../components/subscriptionItem"
import Checkmark from "@material-ui/icons/CheckOutlined"
import { ACCOUNT_TYPES, ACCOUNT_PRICES } from "../types"

const styles = theme => ({
  root: {
    backgroundColor: "white",
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
  },
  reverse: {},
  first: {
    paddingTop: 0,
  },
  textImageModule: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `'img' 'text'`,
    alignItems: "center",
    gridGap: "3rem",
    padding: "4rem 1.5rem",
    maxWidth: 1000,
    margin: "0 auto",

    "&$reverse": {
      [theme.breakpoints.up("sm")]: {
        gridTemplateAreas: `'img text'`,
      },
    },

    "&$first": {
      paddingTop: 0,
    },

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `'text img'`,
      gridGap: "5rem",
      padding: "9rem 2rem",
    },

    "& img": {
      gridArea: "img",
      justifySelf: "end",
      maxWidth: 450,
      width: "100%",
    },
  },
  text: {
    gridArea: "text",
    maxWidth: 500,
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  headline: {
    margin: "1rem 0",
  },
  separator: {
    width: 60,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    margin: "1.5rem 0",
  },

  subscriptionOptions: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "0 20px",
    marginTop: "2rem",

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
    },

    "& ul": {
      listStyle: "none",
      padding: 0,
      margin: "0.5rem 0 0",
    },

    "& li p": {
      display: "inline-block",
    },

    "& li:before": {
      content: '"\\2713"',
      color: theme.palette.primary.main,
      marginRight: 5,
    },
  },
  subscriptionOption: {
    padding: "1.5rem",
    textAlign: "center",
  },
  divider: {
    margin: "2rem 0",
  },
  planButton: {
    marginTop: "1.5rem",
  },
  featureList: {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "center",
    margin: "3rem 0",
    textAlign: "left",

    "& ul": {
      margin: "0 1rem",
      listStyle: "none",
    },

    "& svg": {
      marginRight: "0.5rem",
    },

    "& li": {
      display: "flex",
      alignItems: "center",
    },
  },
})

const Pricing = props => {
  const { classes } = props

  return (
    <PublicLayout
      headline={"Start Building a Better Plan "}
      subheading={
        "Automated forecasting. Unlimited collaboration. No additional fees."
      }
      canonicalUrl="https://launchplan.com/pricing"
    >
      <Helmet>
        <title>Pricing for LaunchPlan Business Plan App | LaunchPlan</title>
        <meta
          name="description"
          content="View the pricing options for LaunchPlan business plan software, including monthly, bi-annual and annual subscriptions. Try it risk-free for 60 days."
        />
        <meta
          property="og:title"
          content="Pricing | LaunchPlan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://launchplan.com/pricing" />
        <meta
          property="og:image"
          content="https://launchplan.com/launchplan-logo-vertical.png"
        />
        <meta
          property="og:image:alt"
          content="The LaunchPlan logo, a rocket launching"
        />
      </Helmet>
      <section className={classes.root}>
        {/* <Typography variant="h5" align="center">Simple Subscription Options</Typography> */}
        <div className={classes.subscriptionOptions}>
          <SubscriptionItem
            title={"Monthly"}
            price={ACCOUNT_PRICES.MONTHLY_ACCOUNT_PRICE}
            description={"No risk. Cancel anytime."}
            buttonText={"Subscribe"}
            to={`${process.env.GATSBY_APP_HOST}/signup?selection=${ACCOUNT_TYPES.MONTHLY_ACCOUNT}`}
          />
          <SubscriptionItem
            title={"Six-month"}
            price={ACCOUNT_PRICES.SIX_MONTH_ACCOUNT_PRICE}
            description={"$15 per month. 17% instant savings."}
            buttonText={"Subscribe"}
            to={`${process.env.GATSBY_APP_HOST}/signup?selection=${ACCOUNT_TYPES.SIX_MONTH_ACCOUNT}`}
          />
          <SubscriptionItem
            title={"Yearly"}
            price={ACCOUNT_PRICES.YEARLY_ACCOUNT_PRICE}
            description={"$12 per month. 33% instant savings."}
            buttonText={"Subscribe"}
            to={`${process.env.GATSBY_APP_HOST}/signup?selection=${ACCOUNT_TYPES.YEARLY_ACCOUNT}`}
          />
          <SubscriptionItem
            title={"Organization"}
            price={"Custom"}
            description={"Manage business plans across an organization."}
            buttonText={"Contact us!"}
            to={`/contact-us`}
          />
        </div>
        <div className={classes.featureList}>
          <ul>
            <li>
              <Checkmark color={"primary"} />
              <Typography>{"Unlimted companies and business plans"}</Typography>
            </li>
            <li>
              <Checkmark color={"primary"} />
              <Typography>{"Unlimted team members*"}</Typography>
            </li>
            <li>
              <Checkmark color={"primary"} />
              <Typography>{"Business Model Canvas support with example"}</Typography>
            </li>
            <li>
              <Checkmark color={"primary"} />
              <Typography>{"Simple, step-by-step instructions"}</Typography>
            </li>
            <li>
              <Checkmark color={"primary"} />
              <Typography>{"Multiple languages supported"}</Typography>
            </li>
          </ul>
          <ul>
            <li>
              <Checkmark color={"primary"} />
              <Typography>{"Automated business projections"}</Typography>
            </li>
            <li>
              <Checkmark color={"primary"} />
              <Typography>{"Modern format with clean charts and graphs"}</Typography>
            </li>
            <li>
              <Checkmark color={"primary"} />
              <Typography>{"Easily export your business plan"}</Typography>
            </li>
            <li>
              <Checkmark color={"primary"} />
              <Typography>{"Example business plan"}</Typography>
            </li>
            <li>
              <Checkmark color={"primary"} />
              <Typography>{"Organization level features available"}</Typography>
            </li>
          </ul>
        </div>
        <Typography align="center" component="p" variant={"caption"}>
          {
            "* Users can invite unlimited team members to collaborate in writing a business plan. To create a new business plan, a user must have their own individual LaunchPlan subscription."
          }
        </Typography>
      </section>
    </PublicLayout>
  )
}

export default withStyles(styles)(Pricing)
